<template>
  <div class="register_body">
    <div class="register">
        <router-link to="/home">
        <div class="back">X</div>
        </router-link>

      <div class="title">布朗英语</div>
     
      <div>
        <div class="title2">注册</div>
        <el-form :model="formData" status-icon :rules="rules" ref="formData" label-width="0">
          <el-form-item label="" prop="phone">
            <el-input v-model="formData.phone" autocomplete="off" placeholder="请输入手机号">
              <i class="icon in-0 el-input__icon" slot="prefix"> </i>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              type="password"
              v-model="formData.password"
              autocomplete="off"
              placeholder="6-16位密码,区分大小写"
            >
              <i class="icon in-1 el-input__icon" slot="prefix"> </i>
            </el-input>
          </el-form-item>

          <el-form-item label="" prop="password">
            <el-input type="password" v-model="formData.passwordAver" autocomplete="off" placeholder="确认密码">
              <i class="icon in-1 el-input__icon" slot="prefix"> </i>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="promptCode">
            <el-input type="text" v-model="formData.promptCode" autocomplete="off" placeholder="请输入提示码">
              <i class="icon in-1 el-input__icon" slot="prefix"> </i>
            </el-input>
          </el-form-item>
          <el-form-item label="" prop="code" style="margin-bottom: 5px">
            <el-row>
              <el-col :span="17">
                <el-input v-model="formData.code" placeholder="请输入验证码">
                  <i class="icon in-2 el-input__icon" slot="prefix"> </i>
                </el-input>
              </el-col>
              <el-col :span="7">
                <div style="text-align: right">
                  <div class="getcode">
                    <el-button @click="getVerify" v-if="show" type="text">获取验证码</el-button>
                    <el-button v-if="!show" type="text">{{ times }}s</el-button>
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-form-item>
        </el-form>
        <div class="div_2">
          <div class="div_change pointer" @click="submitForm">注册</div>
          <div class="div_login pointer" @click="black">使用已有账户登录</div>
        </div>
       
      </div>
   
    </div>
    <div class="cicleLeft"></div>
    <div class="cicleRight"></div>

    
  </div>
</template>

<script>
export default {
  data() {
    return {
      times: 60,
      show: true,
      formData: {
        phone: "",
        password: "",
        passwordAver: "",
        code: "",
        promptCode: "",
      },
      rules: {
        phone: [
          { required: true, message: "请输入手机号", trigger: "blur" },
          { pattern: /^1[3|4|5|6|7|8|9]\d{9}$/, message: "请输入正确的号码格式", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入6-16位密码，区分大小写", trigger: "blur" },
          { pattern: /^[0-9A-Za-z]{6,16}$/, message: "请输入正确的密码格式", trigger: "blur" },
        ],
        passwordAver: [
          { required: true, message: "请确认密码", trigger: "blur" },
          { pattern: /^[0-9A-Za-z]{6,16}$/, message: "请输入正确的密码格式", trigger: "blur" },
        ],
        code: [{ required: true, message: "请输入验证码", trigger: "blur" }],
        promptCode: [{ required: true, message: "请输入验证码", trigger: "blur" }],
      },
    };
  },
  created() {},
  methods: {
    //返回
    black() {
      this.$router.go(-1);
    },
    getVerify() {
      if (this.formData.phone) {
          if (this.formData.password === this.formData.passwordAver) {
        this.show = false;
        this.timer = setInterval(() => {
          this.times--;
          if (this.times === 0) {
            this.show = true;
            clearInterval(this.timer);
            this.times = 60;
          }
        }, 1000);
        this.$api.login.getVerify({ phone: this.formData.phone }).then((res) => {
          
          if (res.data.code === 200) {
            this.$message.success(res.data.data);
          }
        });
           } else {
            // this.$message.warning("两次输入密码不一致");
             this.$message({
                    message: "两次输入密码不一致",
                    type: "warning",
                    offset:'400'
                  });
          }
      } else {  
        // this.$message.warning("请输入手机号");
         this.$message({
                    message: "请输入手机号",
                    type: "warning",
                    offset:'400'
                  });
      }
    },
    submitForm() {
      //提交
      this.$refs["formData"].validate((valid) => {
        if (valid) {
          // if (this.formData.password === this.formData.passwordAver) {
            this.$api.login.register(this.formData).then((res) => {
              if (res.data.code === 200) {
                // this.$message.success("注册成功");
                 this.$message({
                    message: "注册成功",
                    type: "success",
                    offset:'400'
                  });
                sessionStorage.setItem("userInfo", JSON.stringify(res.data.data));
                this.$router.push("/login");
              }
            });
          // } else {
          //   this.$message.warning("两次输入密码不一致");
          // }
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
   .back{
        height:5%;
        width:3%;
        border: 1pt solid #f9be6f;
        color: #f9be6f;
      }
   .back:hover{
          border: none;
           background:rgb(232, 17, 35);
           color: #fff;
          line-height: 27px;
      }
    .back img{
      width: 100%;
      height: 100%;
    }
.register_body {
  .getcode {
    width: 112px;
    border: 1px solid #dcdfe6;
    margin-left: 20px;
    border-radius: 6px;
    text-align: center;
  }
  .back{
        position: absolute;
        font-weight: 550;
        // height: 20px;
        // width: 23px;
        line-height: 27px;
        border-radius: 5px;
       left: 90%;
       top: 10%;

      }
      .back:hover{
        cursor: pointer;

      }
    
  .cicleLeft {
    background: #f9be6f;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    position: fixed;
    bottom: -200px;
    left: -120px;
    z-index: 1;
  }
  .cicleRight {
    background: #f9be6f;
    border-radius: 50%;
    width: 500px;
    height: 500px;
    position: fixed;
    top: -150px;
    right: -150px;
    z-index: 1;
  }
  background: #f2f2f2;
  height: 100vh;
  position: relative;
  min-width: 929px;
  .register {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin: 0 auto;
    width: 929px;
    z-index: 2;
    height: 520px;
    background: #fff;
    border: 2px solid rgb(247, 191, 108);
    border-radius: 17px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title {
      font-size: 27px;
      font-weight: bolder;
    }
    .title2 {
      font-size: 20px;
      margin-top: 20px;
      font-weight: bolder;
      margin-bottom: 21px;
      text-align: left;
    }
    .code {
      width: 458px;
      display: flex;
      margin-top: 18px;
      align-items: center;
      .div_code {
        width: 320px;
        height: 35px;
        border: 1px solid rgba(153, 153, 153, 1);
        border-radius: 6px;
        display: flex;
        align-items: center;
      }
      .text_code {
        outline: none;
        border: none;
        width: 290px;
        color: rgba(153, 153, 153, 1);
        font-size: 17px;
        margin-left: 12px;
      }
      .get_code {
        height: 35px;
        width: 121px;
        border: 1px solid rgba(153, 153, 153, 1);
        line-height: 35px;
        vertical-align: middle;
        text-align: center;
        padding: 0;
        color: #000;
        border-radius: 6px;
        margin-left: 14px;
      }
    }
    .div_2 {
      width: 455px;
      position: relative;
      margin-top: 17px;
      .div_change {
        width: 209px;
        height: 40px;
        background-color: #f7bd66;
        border-radius: 6px;
        color: white;
        line-height: 40px;
        position: absolute;
        left: 0;
        vertical-align: middle;
        text-align: center;
      }
      .div_login {
        font-size: 17px;
        position: absolute;
        height: 40px;
        line-height: 40px;
        vertical-align: middle;
        right: 3px;
        color: rgba(238, 191, 116, 1);
      }
    } 
  }
}
</style>
